import { useForm } from 'vee-validate';
import * as yup from 'yup';

const schema = yup.object({
  id: yup.string(),
  email: yup.string().label('Email address'),
  name: yup.string().required().label('Full name'),
  phone: yup.string().label('Phone')
});
  
const { handleSubmit, errors, defineField, setFieldError, setFieldValue, setValues } = useForm({
  validationSchema: schema,
});

const [email] = defineField('email');
const [name] = defineField('name');
const [phone] = defineField('phone');

export {
  handleSubmit,
  setValues,
  setFieldValue,
  setFieldError,
  errors,
  email,
  name, 
  phone
}