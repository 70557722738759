export default {
  common: {
    bursts: "Tirs",
    statistics: "Statistiques",
    deviceInfo: "Les informations",
    burstCalendar: "Calendrier des tirs",
    statCalendar: "Calendrier des statistiques",
    burstList: "Liste des Tirs",
    burstOf: "Tir du",
    statOf: "Statistique du",
    to: "au",
    archiveFiles: "Liste des fichiers d'archives",
    reportFiles: "Liste des rapports",
    notifLog: "Historique des notifications",
    generateReport: "Generateur de rapports",
    active: "activé",
    disabled: "désactivé",
    cloudStorage: "Sauvegarde dans le nuage",
    password: "Mot de passe",
    updateFilter: "Modifier le filtre",
    saveFilter: "Valider les choix",
    applyFilter: "Filtrer",
    monday: "lundi",
    tuesday: "mardi",
    wednesday: "mercredi",
    thursday: "jeudi",
    friday: "vendredi",
    saturday: "samedi",
    sunday: "dimanche",
    startDate: "Date de début",
    endDate: "Date de fin",
    exportCSV: "Exporter en CSV",
  },
  auth: {
    signin: "Se connecter",
    login: "Connexion",
    register: "S'inscrire",
    signup: "Inscription",
    forgetPass: "Mot de passe oublié ?",
    loggedAs: "Connecté en tant que",
    logout: "Se déconnecter",
    resetPass: "Reinitialiser mot de passe",
    recover: "Envoyer un email de réinitialisation",
    relogin: "",
    or: "Ou",
    contact: "nous contacter",
    username: "Nom d'utilisateur",
    fullname: "Nom complet",
    email: "Adresse email",
    password: "Mot de passe",
    passwordConfirm: "Confirmer votre mot de passe",
    hasAMCODE: "Je possède un code de validation",
  },
  setting: {
    settings: "Configurations",
    recipientList: "Liste des destinataires",
    addNew: "Ajouter",
    recipient: "Destinataire",
    device: "Appareil",
    planning: "Planning",
  },
  ui: {
    edit: "Modifier",
    save: "Sauvegarder",
    back: {
      all: "Revenir à la",
      list: "Liste",
    },
    get: {
      all: "Tous les",
    },
    delete: "Supprimer",
    cancel: "Annuler",
    reload: "Rafraichir",
    show: "Voir",
    download: "Télécharger",
    createdOn: "Créé le",
    name: "Nom",
    level: "Niveau",
    datetime: "Date et heure",
    company: "Entreprise",
    yes: "Oui",
    no: "Non",
    select: "Choix",
    updatePlanning: "Modifier le planning",
    active: "Active",
    inactive: "Inactive",
    email: "Email",
    phone: "Phone",
    event_base_report: "Event base report",
    notification: "Notification",
    technical_alarm: "Technical alarm",
    sms: "SMS",
    measurement: "Measurement",
    sms_notification: "SMS notification",
    email_notification: "Email notification",
    notification_configuration: "Notification configuration",
    notification_recipient: "Notification recipient",
  },
  group: {
    limit: "limites",
    label: "labels",
    name: "nom",
    anomaly: "anomalie",
    process: "Processus",
    measGroup: "Groupe de mesures",
    measRecipient: "destinataire des mesures",
    measNotif: "notification des mesures",
    measNotifLabel: "label des notification de mesure",
    measProcess: "measurement process",
    notifRecipient: "destinataire des notifications",
    measPlanning: "planning des mesures",
  },
  modal: {
    deleteUser: "Delete user from the application",
    deleteOrganization: "Delete organization from the application",
    deleteOrganizationMsg: "Are you sure you want to delete this organization? All of your data will be permanently removed. This action cannot be undone.",
    deleteUserRecipient: "Supprimer l'utilisateur de la liste des destinataires",
    deleteUserRecipientConfirm: "Est-vous sûr de vouloir supprimer cette entré?",
    deleteAlarmRecipient: "Supprimer l'alarme de la liste",
    deleteConfirmMsg: "La suppression est définitive. Etes-vous sûr de vouloir de continuer?",
  },
  chart: {
    din4150: "Graphique DIN-4150-3",
  },
  device: {
    disconnected: "Déconnecté",
    connected: "Connecté",
    contractNumber: "Numéro de contract",
  },
  hover: {
    annomalyDetectionEnabled: "Détection des annomalies activée",
  },
  configuration: {
    manageOrg: "Gérer les organisations",
    manageUser: "Gérer les utilisateurs",
    manageMeta: "Gérer les appareils",
    manageProfile: "Gérer votre profil",
    manageRecipient: "Gérer la liste des receveurs d'alert",
    createOrg: "Créer une organisation",
    createUser: "Ajouter un utilisateur",
    globalMap: "All device in one maps",
    allMeasurement: "Toutes les données de mesures",
  },
  // check translation
  tables: {
    download: "Download",
    show: "Show",
    name: "Name",
    createdOn: "Created on",
    edit: "Modifier",
    delete: "Supprimer",
    comment: "Commenter",
    burst: {
      visible: "Visible",
      notification_level: "Notification level",
      datetime: "Datetime",
      pvs_ppv: "PVS",
      pvs_time: "PVS time [s]",
      ppv_tran: "PPV-TRAN",
      freq: "freq [Hz]",
      ppv: "ppv [mm/s]",
      time: "time [s]",
    },
  },
};
