<script setup lang="ts">
import { computed, ref } from "vue";

import { UserUpdate } from '@/index';
import { useConfigurationStore } from "@modules/configuration/store/configurationStore";

import MultiSelect from "primevue/multiselect";
import Select from "primevue/select";
import InputText from "primevue/inputtext";

import { v4 as uuidv4 } from "uuid";


const props = defineProps<{
  userObjectData: UserUpdate;
  isNewUser: Boolean;
  lensGroups: any[];
}>();

const data = ref({...props.userObjectData})

const emit = defineEmits(["submit-user-form"]);

const configurationStore = useConfigurationStore();

const lensGroupsWithDetails = computed(() => {
  return props.lensGroups.map((g: any) => {

    return {
      ...g,
      lensGroup_name: `${g.location_id} - ${g.lensGroup_application_type} - ${g.lensGroup_name}`,
    };
  });
});

function createNewUser() {
  const formData = { ...props.userObjectData, ...data.value };
  emit("submit-user-form", formData);
}
</script>

<template>
  <div v-if="userObjectData" class="flex pb-8">
    <div class="flex-col flex ml-auto mr-auto items-center w-full lg:w-2/3 md:w-3/5">
      <h1 class="text-2xl mt-8">
        {{ isNewUser ? "Create new user" : "Update user" }}
      </h1>
      <form @submit.prevent="createNewUser" class="flex flex-col space-y-5 size">
      <label for="username">Nickname</label>
      <InputText v-model="data.username" id="username" name="username" type="text" autocomplete="username"
        placeholder="recovib" required="true" />

      <label for="email">User account (Email)</label>
      <InputText v-model="data.user_email" id="email" name="email" type="email" autocomplete="email"
        placeholder="user_email@recovib-io.com" required="true" />
      
      <label for="fullName">Full Name</label>
      <InputText v-model="data.user_fullname" id="fullName" name="fullName" type="text" autocomplete="fullName"
        required="true" />
      
      <label for="user_role">User Role</label>

      <Select v-model="data.user_role" :id="uuidv4()" optionValue="id" optionLabel="role_name" :options="configurationStore.roles" />

      <label for="user_org">User Organization</label>
      <Select v-model="data.user_org" :id="uuidv4()" optionValue="id" optionLabel="org_display_name" :options="configurationStore.organizations" />

      <label for="user_lensGroups">Assigned device(s)</label>
      <MultiSelect v-model="data.user_lensGroups" :id="uuidv4()" optionValue="id" optionLabel="lensGroup_name" :options="lensGroupsWithDetails" filter display="chip" />

      <button type="submit" class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4">
            {{ isNewUser ? "Create" : "Update" }}
      </button>


      </form>
    </div>
  </div>
</template>

<style>
.size {
  width: 500px;
}
</style>
