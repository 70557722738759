<script setup lang="ts">
import { ref, onMounted } from "vue";
import { storeToRefs } from "pinia";

import { useDateTimeStore } from "@use/useDatetime";

import TableData from "@core/components/tables/elements/TableData.vue";
import { useConfigStore } from "@/config/configStore";

import moment from "moment-timezone";

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

const props = defineProps<{ notifications: any[] }>();

const datetime = useDateTimeStore();
const options = { pageSize: 5 };

const statsNotificationColumns = [
  {
    label: "Date",
    id: "date",
    width: "250px",
    isKey: true,
    sortable: true,
    sortItemKey: "date",
    sortItemType: "date",
    formatter: (cell: string) => moment(cell).format(datetime.time24hFormat),
  },

{
    label: "Burst ID",
    id: "burst_id",
    sortable: false,
  },

  {
    label: "Message",
    id: "message",
    sortable: false,
  },

];

const configStore = useConfigStore();
const displayColumns = ref<any>();

onMounted(() => {
  displayColumns.value = statsNotificationColumns;
});
</script>

<template>
  <section class="text-gray-700 body-font relative">
    <div class="container mx-auto items-center px-5 pb-24">
      <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm uppercase py-6">Notifications list:</h2>
      <DataTable v-if="props.notifications?.length > 0" :value="props.notifications" tableStyle="min-width: 50rem" :sortOrder="-1" sortField="datetime" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
      <Column field="date" header="Datetime" :sortable="true">
        <template #body="slotProps">
          <div>
            {{ moment(slotProps.data.date).format("D MMM, yyyy HH:mm:ss") }}
          </div>
        </template> 
      </Column>
      <Column field="burst_id" header="Burst ID"></Column>
      <Column header="Message">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.message }}
          </div>
        </template> 
      </Column>
    </DataTable>
      <!-- <TableData v-if="props.notifications?.length > 0" :columns="displayColumns" :rows="props.notifications" :options="options" /> -->
        <div v-else>Nothing to display on this date</div>
    </div>
  </section>
</template>
