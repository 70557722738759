/// index.d.ts
import "v-calendar/dist/style.css";
import "./main.css";

import "leaflet";
import axios from "axios";
import cloneDeep from "lodash.clonedeep";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// @ts-ignore
import VCalendar from "v-calendar";
import { createApp } from "vue";
import VueAxios from "vue-axios";
//@ts-ignore
import { VueZo, ZoOption } from "vue-zo";

import App from "./App.vue";

import router from "./router";
import { i18n } from "./locales";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

//test-commit
// import VueTailwindModal from "vue-tailwind-modal";
export function resetStore({ store }: { store: any }) {
  const initialState = cloneDeep(store.$state);
  store.$reset = () => store.$patch(cloneDeep(initialState));
}

declare class VueZo {
  static install: (app: any, options?: ZoOption) => void;
}
declare class VCalendar {
  static install: (app: any, options?: any) => void;
}

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
pinia.use(resetStore);

const app = createApp(App)
  .use(pinia)
  .use(router)
  .use(VCalendar, {})
  //ts-ignore
  .use(VueZo, { persistent: true });

app.use(PrimeVue, {
  theme: {
    preset: Aura,
    options: {
      cssLayer: {
        name: 'primevue',
        order: 'tailwind-base, primevue, tailwind-component, tailwind-utilities'
      }
    }
  }
});

app.use(VueAxios, axios);
app.use(i18n);
// app.component("VueTailwindModal", VueTailwindModal);
app.provide("axios", app.config.globalProperties.axios);

router.isReady().then(() => {
  app.mount("#app");
});
