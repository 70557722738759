<template>
  <div v-if="orgObjectData" class="flex pb-8">
    <div class="flex-col flex ml-auto mr-auto items-center w-full lg:w-2/3 md:w-3/5">
      <h1 class="text-2xl mt-8">
        {{ isNewOrganization ? "Create new organization" : "Update organization" }}
      </h1>
      <!-- <form @submit.prevent="createNewOrganization" class="flex flex-col space-y-5 size">
        <BaseForm :formSchema="formatOrgToForms" @form-value-updated="recomposeValue($event)" />
        <SelectMultiInput
          v-if="formatMetaToMuliSelectUsers"
          :key="formatMetaToMuliSelectUsers.id"
          :fieldObj="formatMetaToMuliSelectUsers"
          @edited-input="recomposeWithMultiSelectGroups($event)"
        ></SelectMultiInput>
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          >
            {{ isNewOrganization ? "Create" : "Update" }}
          </button>
        </div>
      </form> -->
      <form class="flex flex-col space-y-3 size" @submit.prevent="createNewOrganization">
        <label for="name">Org Name</label>
        <InputText id="name" type="text" v-model="data.org_name" />
        <label for="name">Org Display Name</label>
        <InputText id="displayName" type="text" v-model="data.org_display_name" class="" />
        <label for="organizations">Organization users</label>
        <MultiSelect v-model="data.org_users" :options="users" dataKey="id" optionLabel="user_email" filter display="chip" placeholder="Search or add Organization users"
          class="size" :fluid="true"/>
        <div>
          <button
            type="submit"
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-blue-500 rounded-md shadow hover:bg-blue-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
          >
            {{ isNewOrganization ? "Create" : "Update" }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { computed, ref } from "vue";

import BaseForm from "@core/components/dynamicForms/BaseForm.vue";
import SelectMultiInput from "@core/components/dynamicForms/inputs/SelectMultiInput.vue";

import { Organization, User } from "@/index";
import { formatMultiSelectToInput } from "@core/components/dynamicForms/utils/formMultiSelectHelpers";
import { formatToForms } from "@core/components/dynamicForms/utils/inputHelpers";
import { useConfigurationStore } from "@modules/configuration/store/configurationStore";

import InputText from 'primevue/inputtext';
import MultiSelect from "primevue/multiselect";
import Chip from 'primevue/chip';

const props = defineProps<{
  orgObjectData: Partial<Organization>;
  isNewOrganization: Boolean;
}>();
const emit = defineEmits(["submit-org-form"]);

console.log(props)

const configurationStore = useConfigurationStore();
const { users } = storeToRefs(configurationStore);
const createOrgFormValue = ref({});
const data = props.isNewOrganization ? ref({
  org_display_name: "",
  org_name: "",
  org_users: []
}) : ref({...props.orgObjectData})

const formatOrgToForms = computed(() => {
  return formatToForms(props.orgObjectData, ["deletedAt"], {
    valueIsTitle: false,
  });
});

const formatMetaToMuliSelectUsers = computed(() => {
  if (users.value && users.value.length > 0) {
    console.log(users.value)
    const userIdList = props.orgObjectData.org_users?.map((u: User) => u.id);
    const userIds = {
      // org_users: props.orgObjectData.org_users,
      org_users: userIdList,
    };
    return formatMultiSelectToInput(userIds, users.value, "user_email", "id", "Organization users");
  }
});

function recompose(newValue: any) {
  createOrgFormValue.value = { ...createOrgFormValue.value, ...newValue };
}

function recomposeValue($event: any) {
  recompose($event);
}
function recomposeWithMultiSelectGroups($event: any) {
  recompose($event.value);
}
function createNewOrganization() {
  // const dataToSend = { ...props.orgObjectData, ...createOrgFormValue.value };
  const dataToSend = {...data.value}
  dataToSend.org_users = data.value.org_users?.map((item: any) => item.id)
  // console.log({...dataToSend})
  emit("submit-org-form", {...dataToSend});
}
</script>
<style>
.size {
  width: 500px;
}
</style>
