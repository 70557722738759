<script lang="ts" setup>
import MainHeading from "@/@core/shared/ui/headings/MainHeading.vue";
import { useUiStore } from "@/@use/uiStore";
// import MetadataTable from "@core/tables/MetadataTable.vue";
import { PlusIcon } from "@heroicons/vue/outline";
import { storeToRefs } from "pinia";
import { ref, computed, onMounted } from "vue";
import { useConfigurationStore } from "../store/configurationStore";
import { useLensStore } from "@/@modules/admin/store/lensStore";
import * as _ from "lodash";
import { useAuthStore } from "@/@modules/auth/authStore";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import { useRouter } from "vue-router";
// @ts-ignore
import { createToaster } from "@meforma/vue-toaster";

const ui = useUiStore();
const router = useRouter();
const configurationStore = useConfigurationStore();
const lens = useLensStore();
const { lensGroups } = storeToRefs(configurationStore);
const auth = useAuthStore();
const { isInLoadingState } = storeToRefs(ui);
 const collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base",
});
const toaster = createToaster({
  position: "top-right",
});

const currentUserEmail = computed(() => {
  return auth.user?.user_email
});

/**
 * Cette fonction calcule et retourne les données de la table.
 * Elle effectue les étapes suivantes :
 * 1. Récupère les groupes de lentilles à partir de la valeur de lensGroups.
 * 2. Pour chaque groupe de lentilles, crée un nouvel objet avec les propriétés suivantes :
 *    - organization: l'ID du locataire du groupe de lentilles.
 *    - cosmosId: l'ID de la première lentille du groupe de lentilles.
 * 3. Supprime la propriété lensGroup_data de chaque objet créé.
 * 4. Retourne les objets uniques en fonction de leur location_id, triés par ordre décroissant.
 *
 * @returns {Array} Les données de la table.
 */
const tableDatas = computed(() => {
  const els = lensGroups.value?.map((lg: any) => {
    const isUserLensGroup = lg.lensGroup_users?.find((user: any) => user.user_email === auth.user?.user_email) ? true : false;
    console.log(lg.lensGroup_users, isUserLensGroup, auth.user?.user_email)
    const severity = isUserLensGroup ? 'info' : 'danger'
    const res = {
      ...lg,
      organization: lg.lensGroup_data.metadata.tenant_id,
      cosmosId: lg.lensGroup_data.metadata.lens_items[0].lens_id,
      isUserLensGroup,
      severity
    };
    delete res.lensGroup_data;
    return res;
  });
  return _.uniqBy(els, "location_id").sort((a: any, b: any) => collator.compare(a.location_id, b.location_id) * -1);
});

const reload = () => {
  lens.setAllOtherLens();
};

const handleClick = (rowItem: any, email: String) => {
  const row = rowItem?.data;
  // const isUserLensGroup = row.lensGroup_users?.find((user: any) => user.user_email === email) ? true : false;
  if (row.isUserLensGroup) {
    lens.setSelectedLensItems({ cosmosId: row.cosmosId, locationId: row.location_id });
    router?.push({ name: "admin-lens-settings-lens-group-id", params: { locationId: row.location_id } });
  } else {
    toaster.info(`Please assign lens group to user`);
  }
}

onMounted(() => {
  reload();
});
</script>

<template>
  <div class="container px-2 py-4 mx-auto">
    <div>
      <MainHeading :title="'Manage devices'" :subtitle="'Add new device'">

        <template v-slot:button>
          <button
            type="button"
            class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
            @click="reload"
            :class="{ isDisabled: isInLoadingState }"
            v-bind:disabled="isInLoadingState"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>

            <span class="ml-4 flex items-start flex-col leading-none">Reload</span>
          </button>

          <router-link
            :to="{
              name: 'configuration-metadata-add',
            }"
            type="button"
            class="uppercase inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500"
          >
            <PlusIcon class="h-6 w-6" />
            <span class="ml-4 flex items-start flex-col leading-none">New</span>
          </router-link>
        </template>
      </MainHeading>
    </div>

    <!-- <MetadataTable :tableData="tableDatas" :currentUserEmail="currentUserEmail"/> -->
    <DataTable :value="tableDatas" tableStyle="min-width: 50rem" :sortOrder="-1" sortField="location_id">
      <Column field="location_id" header="Device ID" :sortable="true"></Column>
      <Column field="lensGroup_name" header="Name"></Column>
      <Column field="lensGroup_status" header="Status"></Column>
      <Column field="lensGroup_application_type" header="Type"></Column>
      <Column field="organization" header="Organization"></Column>
      <Column header=""><template #body="slotProps">
        <Button class="m-auto inline-block px-4 py-1" label="Edit" @click="handleClick(slotProps, currentUserEmail)" :severity="slotProps.data.severity" raised/></template>
      </Column>
    </DataTable>
  </div>
</template>
<style lang="css">
  .p-datatable-column-header-content{
    justify-content: center;
  }
</style>